import { Link, useParams } from "react-router-dom";
import ContentWrapper from "../common/ContentWrapper";
import { Category, getAllProducts } from "./productsProvider";
import styled from "styled-components";
import HoverEffect from "./HoverEffect";

const GridContainer = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0 6%;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 30px;
`;

const StyledImage = styled.img`
  width: 100%;
`;

const Title = styled.span`
  text-transform: uppercase;
  margin-top: 14px;
  color: #000;
  display: block;
`;

function Products() {
  const { category } = useParams<{ category: Category }>();
  const allProducts = getAllProducts(category);

  return (
    <ContentWrapper>
      <div style={{ textAlign: "center", width: "100%" }}>
        <GridContainer>
          {allProducts.map((product, index) => (
            <GridItem key={`${category}-${product.id}`}>
              <Link to={`/product/${product.id}`}>
                <HoverEffect index={index}>
                  <StyledImage
                    src={`/img/products/front/${product.frontImage}`}
                    alt={product.title}
                  />
                  <Title>{product.title}</Title>
                </HoverEffect>
              </Link>
            </GridItem>
          ))}
        </GridContainer>
      </div>
    </ContentWrapper>
  );
}

export default Products;
