import { AnimatePresence } from "framer-motion";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import styled from "styled-components";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import "./App.css";
import Footer from "./components/Footer";
import About from "./components/about/About";
import ContentWrapper from "./components/common/ContentWrapper";
import Logo from "./components/common/Logo";
import Menu from "./components/common/Menu";
import Contact from "./components/contact/Contact";
import Product from "./components/products/Product";
import Products from "./components/products/Products";

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 66px;
  max-width: 1300px;
  margin: 0 auto;
  border-bottom: 1px solid #ccc;
`;

const PageWrapper = styled.div`
  padding: 45px 0 25px 0;
`;

function App() {
  const NotFound = () => (
    <ContentWrapper>
      <h1>404 Not Found</h1>
    </ContentWrapper>
  );

  return (
    <BrowserRouter>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <div style={{ margin: "0 15px" }}>
          <PageWrapper>
            <Header>
              <Logo />
              <Menu />
            </Header>
            <AnimatePresence mode="wait">
              <Routes>
                <Route path="/" element={<Products />} />
                <Route path="/about" element={<About />} />
                <Route path="/products/:category?" element={<Products />} />
                <Route path="/product/:id" element={<Product />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </AnimatePresence>
          </PageWrapper>
        </div>
        <Footer />
      </QueryParamProvider>
    </BrowserRouter>
  );
}

export default App;
