import React from "react";
import ContentWrapper from "../common/ContentWrapper";
import { Heading } from "../common/Heading";
import styled from "styled-components";

const Section = styled.div`
  margin-bottom: 20px;
`;
function Contact() {
  return (
    <ContentWrapper>
      <div>
        <Heading>CONTACT</Heading>
        <Section>
          <p>FOR ALL CUSTOMER AND SALES ENQUIRIES,</p>
          PLEASE CONTACT:{" "}
          <a href="mailto:kristinesvestules@gmail.com">
            kristinesvestules@gmail.com
          </a>
        </Section>
        <Section>
          <p>STUDIO LOCATION</p>
          <p>ZVĀRTAVA MANOR, GAUJIENA PARISH, SMILTENE DISTRICT</p>
          <p>LATVIA, LV-4339 (BY APPOINTMENT ONLY)</p>
        </Section>
      </div>
    </ContentWrapper>
  );
}

export default Contact;
