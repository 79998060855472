export interface Product {
  id: string;
  categoryId: Category;
  category: string;
  title: string;
  description: string;
  color: string;
  size: string;
  weight: string;
  price: string;
  images: string[];
  frontImage: string;
}

export enum Category {
  CANDLES = "candles",
  CANDLE_HOLDERS = "candle-holders",
  FLOWER_POTS_AND_PLANTERS = "pots_planters",
  OTHER = "other",
}
const candleBlack = {
  id: "candle-black",
  categoryId: Category.CANDLES,
  category: "Canldes",
  title: "CANDLE / BLACK",
  description:
    "Made of mass colored black concrete, filled with certified eco soy wax. (ask for different concrete colors)",
  color: "black",
  size: "10 cm x 10 cm",
  weight: "1,1 kg / 260 ml",
  price: "50 EUR",
  frontImage: "candle-black.jpg",
  images: [
    "candle-black-1.jpg",
    "candle-black-3.jpg",
    "candle-black-2.jpg",
    "candle-black-4.jpg",
  ],
};

const flowerPotGray = {
  id: "flower-pot-gray",
  categoryId: Category.FLOWER_POTS_AND_PLANTERS,
  category: "Flower pots and planters",
  title: "FLOWER POT / GRAY",
  description: "Made of natural concrete. (ask for different concrete colors)",
  color: "gray",
  size: "19 cm x 19 cm",
  weight: "3,6 kg",
  price: "55 EUR",
  frontImage: "flower-pot-gray.jpg",
  images: [
    "flower-pot-gray-1.jpg",
    "flower-pot-gray-2.jpg",
    "flower-pot-gray-3.jpg",
  ],
};

const candleHolderTrumpetBlack = {
  id: "candle-holder-trumpet-black",
  categoryId: Category.CANDLE_HOLDERS,
  category: "Candle holders",
  title: "CANDLE HOLDER “TRUMPET” / BLACK",
  description: "Made of mass colored black concrete and cooper.",
  color: "black",
  size: "10 cm x 9,8 cm",
  weight: "0,45 kg",
  price: "35 EUR",
  frontImage: "candle-holder-trumpet-black.jpg",
  images: [
    "candle-holder-trumpet-black-1.jpg",
    "candle-holder-trumpet-black-2.jpg",
    "candle-holder-trumpet-black-3.jpg",
  ],
};

const deskAccessoryTrayRed = {
  id: "desk-accessory-tray-red",
  categoryId: Category.OTHER,
  category: "Other",
  title: "DESK ACCESSORY TRAY / RED",
  description:
    "Made of mass colored red concrete. (ask for different concrete colors)",
  color: "red",
  size: "10 cm x 10 cm",
  weight: "0,8 kg",
  price: "35 EUR",
  frontImage: "desk-accessory-tray-red.jpg",
  images: [
    "desk-accessory-tray-red-1.jpg",
    "desk-accessory-tray-red-2.jpg",
    "desk-accessory-tray-red-3.jpg",
  ],
};

const candleHolderGroundSetThree = {
  id: "candle-holder-ground-set-three",
  categoryId: Category.CANDLE_HOLDERS,
  category: "Candle holders",
  title: "CANDLE HOLDER “GROUND” / SET OF 3",
  description:
    "Made of mass colored concrete and brass. (ask for different concrete colors)",
  color: "",
  size: "6 cm x 10 cm",
  weight: "0,5 kg",
  price: "80 EUR",
  frontImage: "candle-holder-low-set-of-3.jpg",
  images: [
    "candle-holder-low-set-of-3-1.jpg",
    "candle-holder-low-set-of-3-2.jpg",
    "candle-holder-low-set-of-3-3.jpg",
  ],
};

const candleHolderCylinderBlack = {
  id: "candle-holder-cylinder-black",
  categoryId: Category.CANDLE_HOLDERS,
  category: "Candle holders",
  title: "CANDLE HOLDER “CYLINDER” / BLACK",
  description:
    "Made of mass colored black concrete and brass. (ask for different concrete colors)",
  color: "black",
  size: "13 cm x 7 cm",
  weight: "0,6 kg",
  price: "35 EUR",
  frontImage: "candle-holder-black.jpg",

  images: [
    "candle-holder-black-1.jpg",
    "candle-holder-black-2.jpg",
    "candle-holder-black-3.jpg",
  ],
};

const candleGrandGray = {
  id: "candle-grand-gray",
  categoryId: Category.CANDLES,
  category: "Candles",
  title: "CANDLE GRAND / GRAY",
  description:
    "Made of natural concrete, filled with certified eco soy wax. (ask for different concrete colors)",
  color: "gray",
  size: "10,5 cm x 19 cm",
  weight: "2,6 kg",
  price: "120 EUR",
  frontImage: "candle-grand-gray.jpg",
  images: ["candle-grand-gray-1.jpg", "candle-grand-gray-2.jpg"],
};

const planterSupersizedRed = {
  id: "planter-supersized-red",
  categoryId: Category.FLOWER_POTS_AND_PLANTERS,
  category: "Flower pots and planters",
  title: "PLANTER SUPERSIZED / RED",
  description:
    "Made of mass colored red concrete and painted steel. (ask for different concrete colors)",
  color: "red",
  size: "43 cm x 47 cm",
  weight: "40 kg",
  price: "520 EUR",
  frontImage: "planter-supersized-red.jpg",
  images: ["planter-supersized-red-1.jpg", "planter-supersized-red-2.jpg"],
};

const planterSupersizedDarkGray = {
  id: "planter-supersized-dark-gray",
  categoryId: Category.FLOWER_POTS_AND_PLANTERS,
  category: "Flower pots and planters",
  title: "PLANTER SUPERSIZED / DARK GRAY",
  description:
    "Made of mass colored dark gray concrete and painted steel. (ask for different concrete colors)",
  color: "dark gray",
  size: "43 cm x 47 cm",
  weight: "40 kg",
  price: "520 EUR",
  frontImage: "planter-supersized-dark-gray.jpg",
  images: [
    "planter-supersized-dark-gray-1.jpg",
    "planter-supersized-dark-gray-2.jpg",
  ],
};

const allProducts: Product[] = [
  candleBlack,
  flowerPotGray,
  candleHolderTrumpetBlack,
  deskAccessoryTrayRed,
  candleHolderGroundSetThree,
  candleHolderCylinderBlack,
  candleGrandGray,
  planterSupersizedRed,
  planterSupersizedDarkGray,
];

const getProductDetails = (productId: string): Product => {
  const product = allProducts.find((product) => product.id === productId);
  if (!product) {
    throw new Error(`Product with id ${productId} not found`);
  }
  return product;
};

const getAllProducts = (category?: Category): Product[] => {
  if (category) {
    return allProducts.filter((product) => product.categoryId === category);
  }
  return allProducts;
};

export { getProductDetails, getAllProducts };
