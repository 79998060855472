import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";

interface ContentWrapperProps {
  children: React.ReactNode;
}

const Background = styled.div`
  padding: 50px 0;
  @media (max-width: 768px) {
    padding: 15px 0;
  }
`;
const Wrapper = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
`;
function ContentWrapper(props: ContentWrapperProps) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.7 }}
    >
      <Background>
        <Wrapper>{props.children}</Wrapper>
      </Background>
    </motion.div>
  );
}

export default ContentWrapper;
