import { motion } from "framer-motion";
import { gsap } from "gsap";
import React, { useEffect, useRef, useState } from "react";
interface HoverEffectProps {
  index: number;
  children: React.ReactNode;
}
export function HoverEffect(props: HoverEffectProps) {
  const { index, children } = props;
  const elementRef = useRef(null);
  const [isHovering, setIsHovering] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    console.log(hasLoaded);
    const element = elementRef.current;
    if (isHovering && hasLoaded) {
      gsap.to(element, {
        opacity: 0.8,
        duration: 0.6,
        yoyo: true,
        repeat: 1,
        ease: "sin.out",
      });
    }
  }, [hasLoaded, isHovering]);

  return (
    <motion.div
      ref={elementRef}
      whileTap={{ scale: 0.98 }}
      animate={{ y: 0, opacity: 1 }}
      initial={{ y: 30, opacity: 0 }}
      transition={{ delay: 0.05 * index, duration: 0.9 }}
      onHoverStart={() => setIsHovering(true)}
      onHoverEnd={() => setIsHovering(false)}
      onAnimationComplete={() => setHasLoaded(true)}
    >
      {children}
    </motion.div>
  );
}

export default HoverEffect;
