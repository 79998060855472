import React from "react";
import ImageWithNavigation from "./ImageWithNavigation";
import { Product } from "./productsProvider";
import styled from "styled-components";

interface PhotoGalleryProps {
  product: Product;
  onOpen: (index: number) => void;
}

const ThumbnailList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 14px;
  margin-top: 8px;
  margin-bottom: 10px;
`;

const Thumbnail = styled.img`
  cursor: pointer;
  height: 50px;
`;
function PhotoGallery(props: PhotoGalleryProps) {
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const { product, onOpen } = props;
  const handleNavigClick = (direction: "backward" | "open" | "forward") => {
    switch (direction) {
      case "forward":
        setCurrentIndex((currentIndex + 1) % product.images.length);
        break;
      case "backward":
        setCurrentIndex(
          (currentIndex - 1 + product.images.length) % product.images.length
        );
        break;
      case "open":
        onOpen(currentIndex);
        break;
    }
  };

  return (
    <div>
      <ImageWithNavigation
        src={`/img/products/400x400/${product.images[currentIndex]}`}
        alt="Product"
        onClick={(direction) => handleNavigClick(direction)}
      />
      <ThumbnailList>
        {product.images.map((image, i) => (
          <Thumbnail
            key={i}
            src={`/img/products/thumbs/${image}`}
            alt={product.title}
            onClick={() => setCurrentIndex(i)}
          />
        ))}
      </ThumbnailList>
    </div>
  );
}

export default PhotoGallery;
