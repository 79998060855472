import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Image = styled.img`
  height: 43px;
`;

function Logo() {
  return (
    <Link to={"/"}>
      <Image src="/img/logo.svg" />
    </Link>
  );
}

export default Logo;
