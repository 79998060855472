import React from "react";
import { Link, LinkProps } from "react-router-dom";
import styled from "styled-components";
import { Category } from "../products/productsProvider";
import { motion } from "framer-motion";

const MenuWrapper = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
`;

const LiStyled = styled.li`
  position: relative;
  display: inline-block;
  margin: 3px 0 0 42px;
  align-self: flex-start;
  a {
    color: #000;
    text-decoration: none;
    text-transform: uppercase;
  }
  @media (max-width: 768px) {
    margin-left: 16px;
  }
`;

const SubMenuList = styled(motion.ul)`
  padding: 14px 0;
  li {
    list-style: none;
    text-align: left;
  }
  position: absolute;
  width: 240px;
`;

const MenuItem = (
  props: {
    title: string;
    children?: React.ReactNode;
    isSelected?: boolean;
  } & LinkProps
) => {
  const { title, children, ...rest } = props;
  const [isSubmenuVisible, setIsSubmenuVisible] = React.useState(false);
  return (
    <LiStyled
      onMouseEnter={() => setIsSubmenuVisible(true)}
      onMouseLeave={() => setIsSubmenuVisible(false)}
    >
      <Link {...rest}>{title}</Link>
      <div>{isSubmenuVisible && children}</div>
    </LiStyled>
  );
};

const SubMenuItem = (props: { title: string; category: Category }) => {
  const { title, category } = props;
  return (
    <motion.li
      whileHover={{
        transition: { duration: 0.4 },
        filter: "brightness(110%)",
      }}
    >
      <Link to={`/products/${category}`}>{title}</Link>
    </motion.li>
  );
};

function Menu() {
  return (
    <>
      <MenuWrapper>
        <MenuItem title="About" to="/about" />
        <MenuItem title="Products" to="/products">
          <SubMenuList
            animate={{ y: 0, opacity: 1 }}
            initial={{ y: -30, opacity: 0 }}
          >
            <SubMenuItem
              title="CANDLE HOLDERS"
              category={Category.CANDLE_HOLDERS}
            />
            <SubMenuItem title="CANDLES" category={Category.CANDLES} />
            <SubMenuItem
              title="PLANTERS"
              category={Category.FLOWER_POTS_AND_PLANTERS}
            />
          </SubMenuList>
        </MenuItem>
        <MenuItem title="Contact" to="/contact" />
      </MenuWrapper>
    </>
  );
}

export default Menu;
