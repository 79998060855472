import React from "react";
import styled from "styled-components";
import CelebrateSymbol from "./common/CelebrateSymbol";
const CopyRight = styled.div`
  display: flex;
  @media (max-width: 768px) {
    margin-top: 15px;
    justify-content: center;
  }
}`;

const Background = styled.div`
  background-color: #f5f5f5;
  padding: 33px 15px;
  font-size: 8px;
  margin-top: 32px;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 80px;
  max-width: 1300px;
  margin: 0 auto;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

const FooterMenu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0 66px 0 0;
  padding: 0;
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    list-style: none;
  }
  a {
    color: #000;
    text-decoration: none;
    text-transform: uppercase;
    margin: 0 0 0 90px;
    line-height: 2;
  }
  @media (max-width: 768px) {
    justify-content: center;
    margin: 0;
    a {
      margin: 0;
    }
    gap: 20%;
  }
`;

function Footer() {
  const thisYear = new Date().getFullYear();
  return (
    <Background>
      <Wrapper>
        <CopyRight>
          &copy; Kristine Karklina {thisYear} <CelebrateSymbol />
        </CopyRight>
        <FooterMenu>
          <ul>
            <li>
              <a href="/products">Products</a>
            </li>
            <li>
              <a href="/about">About</a>
            </li>
            <li>
              <a href="/contact">Contact</a>
            </li>
          </ul>
          <ul>
            <li>
              <a href="https://www.facebook.com">Instagram</a>
            </li>
            <li>
              <a href="https://www.facebook.com/">Facebook</a>
            </li>
          </ul>
        </FooterMenu>
      </Wrapper>
    </Background>
  );
}

export default Footer;
