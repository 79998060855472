import styled from "styled-components";

interface ImageWithNavigationProps {
  src: string;
  alt: string;
  onClick: (index: "backward" | "open" | "forward") => void;
}

const Container = styled.div`
  width: 100%;
  display: inline-block;
  position: relative;
`;

const ProductImage = styled.img`
  cursor: -moz-zoom-in;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
`;

const ButtonForward = styled.div`
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
  width: 46px;
  align-items: center;
  cursor: pointer;
  height: 100%;
`;

const ButtonBackward = styled(ButtonForward)`
  right: auto;
  left: 0;
`;

const ArrowButtonRight = styled.div`
  border-style: solid;
  border-color: #fff;
  border-width: 1px 1px 0 0;
  width: 18px;
  height: 18px;
  transform: rotate(45deg);
`;
const ArrowButtonLeft = styled(ArrowButtonRight)`
  transform: rotate(-45deg);
  border-width: 1px 0px 0 1px;
  margin-left: 27px;
`;
function ImageWithNavigation(props: ImageWithNavigationProps): JSX.Element {
  const { src, onClick, alt } = props;
  return (
    <Container>
      <ProductImage
        src={src}
        onClick={() => onClick("open")}
        alt={alt}
        width={"100%"}
      />
      <ButtonForward onClick={() => onClick("forward")}>
        <ArrowButtonRight />
      </ButtonForward>
      <ButtonBackward onClick={() => onClick("backward")}>
        <ArrowButtonLeft />
      </ButtonBackward>
    </Container>
  );
}

export default ImageWithNavigation;
