import styled from "styled-components";
import ContentWrapper from "../common/ContentWrapper";
import { motion } from "framer-motion";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6%;
  max-gap: 1px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Image = styled(motion.div)`
  flex: 1;
  height: max-content;
`;

const Description = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-transform: uppercase;

  p {
    margin: 0 0 20px 0;
  }
`;

const Header = styled.p`
  margin: 0 0 20px 0;
  font-weight: 700;
  letter-spacing: 0.9rem;
  font-size: 0.9rem;
`;

function About() {
  return (
    <ContentWrapper>
      <Container>
        <Image
          initial={{ filter: "brightness(160%)" }}
          animate={{ filter: "brightness(100%)" }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <img src="img/kristine_karklina.jpg" width={"100%"} alt="" />
        </Image>
        <Description>
          <Header>Kristine Karklina</Header>
          <p>
            Latvian sculptor and product designer Mrs Kristīne Kārkliņa creates
            unique concrete design objects.
          </p>
          <p>
            She has gathered her experience in functional design while studying
            in Latvian Academy of Arts and later extended her creative capacity
            while working as Art Director in advertising.
          </p>
          <p>
            Kristīne loves the unique nature of concrete, minimalist design and
            simple shapes, and that characterizes her works. Her design objects
            speak about simplicity, provide functionality and promise continuous
            performance.
          </p>
        </Description>
      </Container>
    </ContentWrapper>
  );
}

export default About;
