import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/styles.css";
import ContentWrapper from "../common/ContentWrapper";
import { getProductDetails } from "./productsProvider";

import styled from "styled-components";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import PhotoGallery from "./PhotoGallery";
import { Heading } from "../common/Heading";

const ProductContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Title = styled.span`
  font-weight: bold;
  display: block;
`;

const PhotoGalleryWrapper = styled.div`
  flex: 1.25;
  padding-right: 32px;
  @media (max-width: 768px) {
    padding: 0;
  }
}`;
const Info = styled.div`
  flex: 1;
  text-transform: uppercase;
  margin-top: 32px;
  p {
    margin-bottom: 12px;
  }
`;

function Products() {
  const navigate = useNavigate();
  let params = useParams<{ id: string }>();

  const product = getProductDetails(params.id as string);
  const [lightboxIndex, setLightboxIndex] = useState(-1);

  if (!params.id) {
    navigate("/not-found");
    return null;
  }

  return (
    <ContentWrapper>
      <ProductContentWrapper>
        <PhotoGalleryWrapper>
          <PhotoGallery
            product={product}
            onOpen={(index) => setLightboxIndex(index)}
          />
        </PhotoGalleryWrapper>
        <Info>
          <Heading>{product.title}</Heading>
          <p>{product.description}</p>
          <p>
            <Title>Dimensions</Title>
            {product.size}
          </p>
          <p>
            <Title>Price</Title>
            &euro; {product.price}
          </p>
          <a href={"/contact"}>Contact for enquiries</a>
        </Info>
      </ProductContentWrapper>
      <Lightbox
        open={lightboxIndex >= 0}
        close={() => setLightboxIndex(-1)}
        slides={product.images.map((image) => ({
          src: `/img/products/${image}`,
          alt: product.title,
        }))}
        plugins={[Thumbnails]}
        carousel={{ finite: true }}
        thumbnails={{
          vignette: true,
        }}
        index={lightboxIndex}
      />
    </ContentWrapper>
  );
}

export default Products;
