import React from "react";

const currentDate = new Date();
const currentMonth = currentDate.getMonth() + 1; // Add 1 because getMonth() returns 0-11

function getSymbol() {
  let symbol = "";
  switch (currentMonth) {
    case 8: // August (11th August - Birthday)
      const currentDay = currentDate.getDate();
      if (currentDay === 11) {
        symbol = "\u{1F382}"; // Birthday cake symbol
      }
      break;

    case 10: // October (Halloween - 31st October)
      symbol = "\u{1F383}"; // Pumpkin symbol
      break;

    case 12: // December (Christmas - 25th December)
      const christmasDay = 25;
      if (currentDate.getDate() === christmasDay) {
        symbol = "\u{1F384}"; // Christmas tree symbol
      }
      break;

    case 4: // April (Easter)
      const easterDate = new Date(currentDate.getFullYear(), 3, 0);
      const easterDay = easterDate.getDate();
      const daysUntilEaster = easterDay - currentDate.getDate();
      if (daysUntilEaster >= 0 && daysUntilEaster <= 7) {
        symbol = "\u{1F95A}"; // Egg symbol
      }
      break;

    default:
      symbol = "";
  }

  return symbol;
}

function CelebrateSymbol() {
  return <span style={{ fontSize: "21px" }}>{getSymbol()}</span>;
}

export default CelebrateSymbol;
